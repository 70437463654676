<!--
About Us
-->
<template>
  <div class="bg-white mt-3">
    <div v-for="(item, index) in (this.data)" :key="index" class="container py-4">
      <h1 class="col-12 text-center my-4 text-blue">{{ item.title }}</h1>
      <div class="text-blue" v-html="item.text"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "module5",
  props: {
    data: {data: [Array], required: true}
  },
}
</script>

<style scoped>

</style>
